import type { ReactNode } from "react";
import { BadgeDot } from "../../components/Badge.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { WMeter } from "../../components/WMeter.tsx";
import type { AdminHostWithType } from "./hostQuery.ts";
import { osIcons } from "./HvOs.tsx";

export function ModalHeaderBlock({ item, children }: { item: AdminHostWithType; children?: ReactNode }) {
    const {
        name,
        ip,
        os,
        allocated_vcpu,
        vcpu_free,
        usable_vcpu_amount,
        allocated_vmem,
        vmem_free,
        usable_vmem_amount,
        total_cpus,
        total_mem_gb,
        is_accepting_workloads,
    } = item;
    const icon = osIcons[os];

    const cpuTooltip = (
        <div>
            <BadgeDot color="color-primary">
                vCPU allocated: <b>{allocated_vcpu.toFixed(0)}</b>
            </BadgeDot>

            <BadgeDot color={is_accepting_workloads ? "color-primary-hover-alpha" : "color-grey-4"}>
                {is_accepting_workloads ? "vCPU available" : "Not available"}: <b>{vcpu_free.toFixed(0)}</b>
            </BadgeDot>
        </div>
    );

    const ramTooltip = (
        <div>
            <BadgeDot color="color-primary">
                vRAM allocated: <b>{allocated_vmem.toFixed(0)}</b> GB
            </BadgeDot>

            <BadgeDot color={is_accepting_workloads ? "color-primary-hover-alpha" : "color-grey-4"}>
                {is_accepting_workloads ? "vRAM available" : "Not available"}: <b>{vmem_free.toFixed(0)}</b> GB
            </BadgeDot>
        </div>
    );

    const cpuPercent = (allocated_vcpu / usable_vcpu_amount) * 100;
    const ramPercent = (allocated_vmem / usable_vmem_amount) * 100;

    return (
        <div className="flex gap-3 items-center justify-between lightBlock">
            {icon && <MaskIcon className={`${icon} color-primary size-2.5rem`} />}
            <div className="flex-grow VStack gap-0.5">
                <div>
                    <b>{name}</b> ({ip})
                </div>

                <div className="color-muted">
                    {total_cpus} vCPUs, {total_mem_gb} GB vRAM
                </div>
            </div>

            <div>
                <WMeter
                    grey={!is_accepting_workloads}
                    title="vCPU allocation"
                    value={cpuPercent}
                    tooltip={cpuTooltip}
                />
            </div>

            <div>
                <WMeter
                    grey={!is_accepting_workloads}
                    title="vRAM allocation"
                    value={ramPercent}
                    tooltip={ramTooltip}
                />
            </div>

            {children}
        </div>
    );
}
