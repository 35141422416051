import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type { AdminHost, AdminHostUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { type ApiClient, getResponseData } from "../../modules/api/apiClient.ts";
import { atomAllLocationsQuery } from "../../modules/location/atomAllLocationsQuery.ts";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export interface Params {
    location: string;
}

export interface AdminHostWithType extends AdminHost, Params {
    $type: "host";
}

export type Response = Map<AdminHost["uuid"], AdminHostWithType>;

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["base-operator/host/list"] : ["base-operator/host/list", params.location];
}

function hostFromData(data: AdminHost, location: string): AdminHostWithType {
    return { ...data, $type: "host", location };
}

export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/base-operator/host/list", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return mapFromEntries(response, (host: AdminHost) => [host.uuid, hostFromData(host, location)]);
        },
    });
}

export const adminHostQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { location }),
);

export const adminAllHostsQueryAtom = atomAllLocationsQuery(adminHostQueryAtom, (results) => mergeMaps(results));

//#region Mutations
export const hostUpdateMutation = adminMutation((apiClient) => ({
    entity: "host",
    operation: "update",
    requestFn: async ({ body, location }: { body: AdminHostUpdateBody; location: string }) =>
        await apiClient.PUT("/{location}/base-operator/admin/host_flags", {
            params: { path: { location: location } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: getQueryKey,
}));
//#endregion Mutations
