import FF from "../../components/forms/FormField.module.css";

import type { AdminHostUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { Badge, type BadgeProps } from "../../components/Badge.tsx";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { cn } from "../../utils/classNames.ts";
import type { AdminHostWithType } from "./hostQuery.ts";
import { hostUpdateMutation } from "./hostQuery.ts";
import { ModalHeaderBlock } from "./ModalHeaderBlock.tsx";

interface StatusBadgeModalProps {
    item: AdminHostWithType;
}

const statusTypes: Record<
    string,
    {
        label: string;
        color: BadgeProps["color"];
        description: string;
        flags: Record<string, boolean>;
    }
> = {
    AVAILABLE: {
        label: "AVAILABLE",
        color: "success",
        description: "Host is considered a valid target for new and migrated VMs. Existing VMs will keep running",
        flags: {
            is_accepting_workloads: true,
            is_maintenance: false,
            is_offline: false,
        },
    },
    "NOT ACCEPTING": {
        label: "NOT ACCEPTING",
        color: "warning",
        description:
            "Host is not available for new VMs, but existing VMs will continue running. Admin will still be able to explicitly migrate VMs to that host",
        flags: {
            is_accepting_workloads: false,
            is_maintenance: false,
            is_offline: false,
        },
    },
    MAINTENANCE: {
        label: "MAINTENANCE",
        color: "error",
        description:
            "All VMs on this host will be automatically migrated to other hosts. Use this if the host is accessible and you want to make it empty",
        flags: {
            is_accepting_workloads: false,
            is_maintenance: true,
            is_offline: false,
        },
    },
    OFFLINE: {
        label: "OFFLINE",
        color: "text",
        description:
            "Host is considered to be unavailable, all VMs on this host will be automatically recreated on other hosts (it is like doing Force Stop and Start to all its VMs). Use only if you are sure that there are no running VMs on that host!",
        flags: {
            is_accepting_workloads: false,
            is_maintenance: false,
            is_offline: true,
        },
    },
};

export function StatusBadge({ item }: StatusBadgeModalProps) {
    let status = statusTypes["NOT ACCEPTING"];

    if (item.is_offline) {
        status = statusTypes.OFFLINE;
    } else if (item.is_maintenance) {
        status = statusTypes.MAINTENANCE;
    } else if (item.is_accepting_workloads) {
        status = statusTypes.AVAILABLE;
    }

    return <Badge color={status.color}>{status.label}</Badge>;
}

export function StatusBadgeButton(props: StatusBadgeModalProps) {
    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <StatusBadge {...props} />
                </EditableButton>
            }
        >
            <StatusBadgeModalContent {...props} />
        </WModal>
    );
}

function getStatus(item: AdminHostWithType) {
    let status = statusTypes["NOT ACCEPTING"];

    if (item.is_offline) {
        status = statusTypes.OFFLINE;
    } else if (item.is_maintenance) {
        status = statusTypes.MAINTENANCE;
    } else if (item.is_accepting_workloads) {
        status = statusTypes.AVAILABLE;
    }

    return status.label;
}

function StatusBadgeModalContent({ item }: StatusBadgeModalProps) {
    const defaultStatus = getStatus(item);
    const [status, setStatus] = useState<string>(defaultStatus);
    const updateMutation = useStandardMutation(hostUpdateMutation);

    async function onSubmit() {
        const statuses = statusTypes[status].flags;
        await updateMutation.mutateAsync({
            body: {
                uuid: item.uuid,
                ...statuses,
            } as AdminHostUpdateBody,
            location: item.location,
        });
    }

    return (
        <WModalContent title="Change Host Status" label="Change" modalAction={async () => await onSubmit()}>
            <ModalHeaderBlock item={item}>
                <StatusBadge item={item} />
            </ModalHeaderBlock>

            <div className="pt-3 pb-2">Change status*</div>
            <div className={FF.FormFieldRadioGroup}>
                {Object.keys(statusTypes).map((item) => {
                    const type = statusTypes[item];
                    const isSelected = status === item;

                    return (
                        <div
                            onClick={() => setStatus(item)}
                            key={item}
                            data-selected={isSelected ? true : undefined}
                            className={cn(FF.FormFieldIconRadio, FF.Size)}
                        >
                            <div className={cn(FF.Header, "py-1")}>
                                <Badge reverse={isSelected} noDot color={type.color}>
                                    {item}
                                </Badge>
                            </div>

                            <div className="p-2 font-size-small">{type.description}</div>
                        </div>
                    );
                })}

                <p className="color-muted font-size-small grid-col-span-2">
                    Changing the host flag can be a dangerous operation, read the flag description carefully before
                    submitting! Use this feature only if you know what you are doing.
                </p>
            </div>
        </WModalContent>
    );
}
