import { MaskIcon } from "../../components/icon/MaskIcon.tsx";

export const osIcons: Record<string, string> = {
    linux: "jp-linux-icon",
    windows: "jp-windows-icon",
    multi: "jp-web-icon",
};

export function HvOs({ value }: { value: string }) {
    const icon = osIcons[value];
    return (
        <div className="HStack gap-1">
            {icon && <MaskIcon className={`${icon} color-muted size-0.875rem`} />}
            {value}
        </div>
    );
}
